import { Filter } from 'components/common';
import { useOptions } from 'hooks';
import { PropsWithChildren } from 'react';
import { FindAllOptionsDto } from 'types';

type FilterOptionsProps = PropsWithChildren &
  Pick<FindAllOptionsDto, 'optionTypeId' | 'service'> & {
    title?: string;
  };

const FilterOptions = ({ optionTypeId, service, title, ...props }: FilterOptionsProps) => {
  const { data } = useOptions({
    optionTypeId,
    service
  });
  return (
    <Filter.Checkbox
      {...props}
      options={data.map((o) => ({
        label: o.name,
        value: o.optionId
      }))}
      title={title}
    />
  );
};
export default FilterOptions;
