import { Layout, Space, Typography } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from 'store';
import { twMerge } from 'tailwind-merge';
import { ID_CRM_HEADER } from 'utils';

const { Header: HeaderAntd } = Layout;

const Header = () => {
  const location = useLocation();
  const { shiftMeActive, loadingShiftMeActive } = useSelector((state: RootState) => state.shifts);
  useEffect(() => {
    const crmHeader = document.getElementById(ID_CRM_HEADER);
    if (crmHeader) {
      crmHeader.innerHTML = '';
    }
  }, [location.pathname]);
  return (
    <HeaderAntd className='flex items-center bg-white px-8'>
      <div id={ID_CRM_HEADER}></div>
      <div
        className={twMerge(
          'items-center  ml-auto',
          loadingShiftMeActive && 'skeleton-active min-h-10 min-w-[200px] rounded-base'
        )}
      >
        <Space split={<Typography.Text> • </Typography.Text>}>
          {!loadingShiftMeActive && shiftMeActive ? (
            <>
              <Typography.Text>{shiftMeActive?.code}</Typography.Text>
              <Typography.Text>{shiftMeActive?.name}</Typography.Text>
              <Typography.Text>{`${shiftMeActive?.fromTime} - ${shiftMeActive?.toTime}`}</Typography.Text>
            </>
          ) : null}
        </Space>
      </div>
    </HeaderAntd>
  );
};
export default Header;
