import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { shiftsApi } from 'services';
import { UserShiftDto } from 'types';

interface ShiftsState {
  shiftMeActive: UserShiftDto | null;
  loadingShiftMeActive: boolean;
}

const initialState: ShiftsState = {
  shiftMeActive: null,
  loadingShiftMeActive: true
};

const shiftsSlice = createSlice({
  name: 'shifts',
  initialState,
  reducers: {
    setShiftMeActive: (state, action: PayloadAction<UserShiftDto>) => {
      state.shiftMeActive = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(shiftsApi.endpoints.getShiftMeActive.matchPending, (state) => {
        state.loadingShiftMeActive = true;
      })
      .addMatcher(shiftsApi.endpoints.getShiftMeActive.matchFulfilled, (state, action) => {
        state.shiftMeActive = action.payload.data;
        state.loadingShiftMeActive = false;
      })
      .addMatcher(shiftsApi.endpoints.getShiftMeActive.matchFulfilled, (state, action) => {
        state.loadingShiftMeActive = false;
      });
  }
});

export const { setShiftMeActive } = shiftsSlice.actions;

export default shiftsSlice.reducer;
