import { useLocation } from 'react-router-dom';
import { MenuItem, SidebarMenuItems } from 'utils';

export function useActiveMenu() {
  const location = useLocation();
  const pathnameArr = location.pathname.replace('/', '').split('/');
  const getSelectedKeys = (
    items: MenuItem[],
    path: string
  ): { selectedMenu: string | null; selectedSubMenu: string | null } => {
    for (let item of items) {
      if (item.children) {
        for (let child of item.children) {
          if (child.href === path) {
            return { selectedMenu: item.key, selectedSubMenu: child.key };
          }
        }
      }
      if (item.href === path) {
        return { selectedMenu: item.key, selectedSubMenu: null };
      }
    }
    return { selectedMenu: SidebarMenuItems[0].key, selectedSubMenu: null };
  };
  return getSelectedKeys(SidebarMenuItems, `/${pathnameArr[0]}`);
}
