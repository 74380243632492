export const userGroupsMessages = {
  name: 'Nhóm người dùng',
  information: 'Thông tin nhóm',
  userGroupId: 'Mã quản lý',
  userGroupName: 'Tên ',
  userGroupQuantity: 'Thành viên',
  createNew: 'Tạo mới nhóm',
  update: 'Cập nhật nhóm',
  organizationUnit: 'Đơn vị/ phòng đội',
  description: 'Mô tả',
  shortName: 'Tên thu gọn',
  permission: 'Phân quyền',
  roles: 'Nhóm quyền',
  users: 'Thành viên',
  updateUsers: 'Cập nhật thành viên',
  updatePermissions: 'Cập nhật quyền'
};
