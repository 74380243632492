import { createApi } from '@reduxjs/toolkit/query/react';
import {
  CreateUserGroupDto,
  FindAllUserGroupDto,
  ResponsePagingDto,
  ResponseSuccessDto,
  UpdateUserGroupDto,
  UserGroupCompactDto,
  UserGroupDto
} from 'types';
import axiosBaseQuery from 'utils/base-api';

export const userGroupsApi = createApi({
  reducerPath: 'userGroupsApi',
  tagTypes: ['user_groups', 'user_groups_detail'],
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getUserGroups: builder.query<ResponsePagingDto<UserGroupDto>, FindAllUserGroupDto>({
      query: (params) => ({
        url: '/user_groups',
        method: 'get',
        params
      }),
      providesTags: (result) =>
        result && result.data.rows.length > 0
          ? result.data.rows.map(({ userGroupId }) => ({
              type: 'user_groups',
              id: userGroupId
            }))
          : ['user_groups']
    }),

    getUserGroupsOptions: builder.query<ResponsePagingDto<UserGroupCompactDto>, FindAllUserGroupDto>({
      query: (params) => ({
        url: '/user_groups/options',
        method: 'get',
        params
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge: (currentCache, newItems, { arg }) => {
        if (arg.pageIndex !== 1) {
          currentCache.data.rows.push(...newItems.data.rows);
        } else currentCache.data.rows = newItems.data.rows;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.keyword !== previousArg?.keyword || currentArg?.pageIndex !== previousArg?.pageIndex;
      },
      providesTags: ['user_groups']
    }),

    getUserGroupDetail: builder.query<ResponseSuccessDto<UserGroupDto>, number>({
      query: (userGroupId) => ({ url: `/user_groups/${userGroupId}`, method: 'get' }),
      providesTags: (result) => (result ? [{ type: 'user_groups_detail', id: result.data.userGroupId }] : [])
    }),

    createUserGroup: builder.mutation<ResponseSuccessDto<UserGroupDto>, CreateUserGroupDto>({
      query: (newUserGroup) => ({
        url: '/user_groups',
        method: 'post',
        data: newUserGroup
      }),
      invalidatesTags: ['user_groups']
    }),
    updateUserGroup: builder.mutation<ResponseSuccessDto<UserGroupDto>, UpdateUserGroupDto>({
      query: ({ userGroupId, ...updateUserGroup }) => ({
        url: `/user_groups/${userGroupId}`,
        method: 'patch',
        data: updateUserGroup
      }),
      invalidatesTags: (_result, _error, param) => [
        { type: 'user_groups', id: param.userGroupId },
        { type: 'user_groups_detail', id: param.userGroupId }
      ]
    }),
    deleteUserGroup: builder.mutation<void, number>({
      query: (userGroupId) => ({
        url: `/user_groups/${userGroupId}`,
        method: 'delete'
      })
    })
  })
});

export const {
  useGetUserGroupsOptionsQuery,
  useGetUserGroupsQuery,
  useGetUserGroupDetailQuery,
  useCreateUserGroupMutation,
  useUpdateUserGroupMutation,
  useDeleteUserGroupMutation
} = userGroupsApi;
