import { Loadable } from 'components';
import { useGetProfile } from 'hooks';
import ErrorBoundary from 'pages/500';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { routes } from 'routes';

function App() {
  const router = createBrowserRouter(routes);
  const { isLoading, isError } = useGetProfile();

  if (isLoading && !isError) return <div className='h-svh w-svw bg-red-500'>loading</div>;
  return (
    <ErrorBoundary>
      <Loadable>
        <RouterProvider router={router} />
      </Loadable>
    </ErrorBoundary>
  );
}

export default App;
