import { useState } from 'react';
import { useGetReasonsQuery } from 'services';
import { FindAllReasonDto } from 'types';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from 'utils';
const pageSize = DEFAULT_PAGE_SIZE;
export function useReasonsPaging({ keyword }: Omit<FindAllReasonDto, 'pageIndex' | 'pageSize'>) {
  const [pageIndex, setPageIndex] = useState(DEFAULT_PAGE_INDEX);
  const {
    data: reasonsResponse,
    error,
    isLoading,
    isFetching
  } = useGetReasonsQuery({
    pageIndex: pageIndex,
    pageSize,
    keyword
  });

  const handleChangePage = (newPageIndex: number) => {
    setPageIndex(newPageIndex);
  };

  return {
    reasons: reasonsResponse?.data.rows || [],
    count: reasonsResponse?.data.count || 0,
    pageIndex,
    isLoading: isFetching || isLoading,
    error,

    handleChangePage
  };
}
