import { CSSProperties, ReactNode, useEffect, useRef } from 'react';

type PropsType = {
  children?: ReactNode;
  hasMore?: boolean;
  next: () => any;
  loader?: ReactNode;
  className?: string;
  height?: number | string;
  maxHeight?: number | string;
  style?: CSSProperties;
  endMessage?: ReactNode;
};

function InfiniteScroll(props: PropsType) {
  const pageEndRef = useRef(null);

  useEffect(() => {
    if (props.hasMore === true) {
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          props.next();
        }
      });

      if (pageEndRef.current) {
        observer.observe(pageEndRef.current);
      }

      return () => {
        if (pageEndRef.current) {
          observer.unobserve(pageEndRef.current);
        }
      };
    }
  }, [props.hasMore]);

  return (
    <div
      className={props.className}
      style={{
        height: props.height,
        maxHeight: props.maxHeight,
        overflow: props.height || props.maxHeight ? 'auto' : 'hidden',
        ...props.style
      }}
    >
      {props.children}
      <div ref={pageEndRef}>{props.hasMore ? props.loader : props.endMessage}</div>
    </div>
  );
}

export default InfiniteScroll;
