import { useState } from 'react';
import { useGetServiceInstructionsQuery } from 'services';
import { FindAllServiceInstructionsDto } from 'types';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from 'utils';
const pageSize = DEFAULT_PAGE_SIZE;
export function useServiceInstructionsPaging({
  isActive,
  parentId,
  keyword
}: Omit<FindAllServiceInstructionsDto, 'pageIndex' | 'pageSize'>) {
  const [pageIndex, setPageIndex] = useState(DEFAULT_PAGE_INDEX);
  const {
    data: serviceTypesResponse,
    error,
    isLoading,
    isFetching
  } = useGetServiceInstructionsQuery({
    pageIndex: pageIndex,
    pageSize,
    keyword,
    parentId,
    isActive
  });
  const handleChangePage = (newPageIndex: number) => {
    setPageIndex(newPageIndex);
  };

  return {
    serviceTypesWithInstructions: serviceTypesResponse?.data.rows,
    count: serviceTypesResponse ? serviceTypesResponse.data.count : 0,
    pageIndex,
    isLoading: isFetching || isLoading,
    error,
    handleChangePage
  };
}
