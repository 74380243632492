import { debounce } from 'lodash';
import { useCallback, useState } from 'react';
import { useGetOptionsQuery } from 'services/options';
import { FindAllOptionsDto } from 'types';
export function useOptions({ optionTypeId, service }: Pick<FindAllOptionsDto, 'optionTypeId' | 'service'>) {
  const [keyword, setKeyword] = useState('');
  const {
    data: optionsResponse,
    error,
    isLoading
  } = useGetOptionsQuery({
    keyword,
    optionTypeId,
    service
  });

  const debouncedKeywordChange = useCallback(
    debounce((keywordValue: string) => {
      setKeyword(keywordValue);
    }, 500),
    [setKeyword]
  );

  const handleSearch = (value: string) => {
    debouncedKeywordChange(value);
  };

  return {
    data: optionsResponse?.data.rows || [],
    count: optionsResponse?.data.count || 0,
    isLoading,
    error,

    keyword,
    handleSearch
  };
}
