export * from './customer-support-information.messages';
export * from './frequently-asked-questions.messages';
export * from './login.messages';
export * from './notification.messages';
export * from './organization-units.messages';
export * from './permissions.messages';
export * from './reasons.messages';
export * from './roles.messages';
export * from './service-instructions.messages';
export * from './service-types.messages';
export * from './shifts.messages';
export * from './sidebar-menu.messages';
export * from './user-groups.messages';
export * from './users.messages';
export * from './validate.messages';
export * from './vip-customers.messages';
export * from './working-schedules.messages';

export const messages = {
  index: 'STT',
  appName: 'CRM v2 TT CSKH',
  toDay: 'Hôm nay',
  home: 'Trang chủ',
  login: 'Đăng nhập',
  loginSuccess: 'Đăng nhập thành công!',
  logout: 'Đăng xuất',
  pageNotFound: 'Xin lỗi, trang bạn truy cập không tồn tại.',
  somethingWentWrong: 'Xin lỗi, đã có lỗi xảy ra.',
  loadingPage: 'Đang tải trang...',
  loadingProfile: 'Đang tải thông tin người dùng...',
  profile: 'Hồ sơ cá nhân',
  changeAvatar: 'Đổi ảnh đại diện',
  deleteAvatar: 'Xóa ảnh đại diện',
  confirmButtonText: 'Xác nhận',
  saveButtonText: 'Lưu',
  cancelButtonText: 'Hủy',
  createButtonText: 'Tạo mới',
  datePickerPlaceHolder: 'Ngày tháng năm',
  userPage: 'Nhân viên',
  fileFormatError: 'File không đúng định dạng',
  upload: 'Tải lên',
  selectAll: 'Chọn tất cả',
  editButtonText: 'Chỉnh sửa',
  urlInvalid: 'URL không hợp lệ',
  deleteButtonText: 'Xóa',
  createdBy: 'Người tạo',
  updatedBy: 'Người cập nhật',
  status: 'Trạng thái',
  statusEnum: {
    active: 'Hoạt động',
    inactive: 'Không hoạt động'
  }
};
