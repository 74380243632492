import { SettingOutlined, UserAddOutlined } from '@ant-design/icons';
import {
  CalendarIcon,
  InstructionsIcon,
  OrganizationUnitIcon,
  PermissionIcon,
  QuestionIcon,
  ReasonBusyIcon,
  RoleIcon,
  ServiceIcon,
  ShiftIcon,
  SupportInformation,
  UserGroupIcon,
  UserIcon,
  VipIcon
} from 'assets';
import { sidebarMenuMessages } from 'messages/sidebar-menu.messages';
import { ReactNode } from 'react';
import { ROUTE } from 'routes/constants';

export type MenuItem = {
  key: string;
  name: string | ReactNode;
  icon: ReactNode;
  href: string;
  children?: MenuItem[];
};
export const SidebarMenuItems: MenuItem[] = [
  {
    key: 'config',
    name: sidebarMenuMessages.config,
    icon: <SettingOutlined />,
    href: ROUTE.ORGANIZATION_UNITS,
    children: [
      {
        key: 'organizationUnit',
        name: sidebarMenuMessages.organizationUnit,
        icon: <OrganizationUnitIcon />,
        href: ROUTE.ORGANIZATION_UNITS
      },
      {
        key: 'customerSupportInformation',
        name: sidebarMenuMessages.customerSupportInformation,
        icon: <SupportInformation />,
        href: ROUTE.CUSTOMER_SUPPORT_INFORMATION
      },
      {
        key: 'serviceTypes',
        name: sidebarMenuMessages.serviceTypes,
        icon: <ServiceIcon />,
        href: ROUTE.SERVICE_TYPES
      },
      {
        key: 'serviceInstructions',
        name: sidebarMenuMessages.serviceInstructions,
        icon: <InstructionsIcon />,
        href: ROUTE.SERVICE_INSTRUCTIONS
      },
      {
        key: 'frequentlyAskedQuestions',
        name: sidebarMenuMessages.frequentlyAskedQuestions,
        icon: <QuestionIcon />,
        href: ROUTE.FREQUENTLY_ASKED_QUESTION
      },
      {
        key: 'workingSchedules',
        name: sidebarMenuMessages.workingSchedules,
        icon: <CalendarIcon />,
        href: ROUTE.WORKING_SCHEDULES
      },
      {
        key: 'shifts',
        name: sidebarMenuMessages.shifts,
        icon: <ShiftIcon />,
        href: ROUTE.SHIFTS
      },
      {
        key: 'reasons',
        name: sidebarMenuMessages.reasons,
        icon: <ReasonBusyIcon />,
        href: ROUTE.REASONS
      },
      {
        key: 'vipCustomers',
        name: sidebarMenuMessages.vipCustomers,
        icon: <VipIcon />,
        href: ROUTE.VIP_CUSTOMERS
      }
    ]
  },
  {
    key: 'usersAndPermissions',
    name: sidebarMenuMessages.usersAndPermissions,
    icon: <UserAddOutlined />,
    href: ROUTE.USERS,
    children: [
      {
        key: 'users',
        name: sidebarMenuMessages.users,
        icon: <UserIcon />,
        href: ROUTE.USERS
      },
      {
        key: 'userGroups',
        name: sidebarMenuMessages.userGroups,
        icon: <UserGroupIcon />,
        href: ROUTE.USER_GROUPS
      },
      {
        key: 'permissions',
        name: sidebarMenuMessages.permissions,
        icon: <PermissionIcon />,
        href: ROUTE.PERMISSIONS
      },
      {
        key: 'roles',
        name: sidebarMenuMessages.roles,
        icon: <RoleIcon />,
        href: ROUTE.ROLES
      }
    ]
  }
];
