import { Form, FormInstance, Input, notification, Spin } from 'antd';
import { FormItem } from 'components/common/form-item';
import { SelectOptions } from 'components/options';
import { SelectOrganizationUnits } from 'components/organization-units';
import { SelectPermissions } from 'components/permissions';
import { SelectRolesOptions } from 'components/roles';
import { SelectUserGroups } from 'components/user-groups';
import { validateMessages } from 'messages';
import { usersMessages } from 'messages/users.messages';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useCreateUserMutation, useUpdateUserMutation } from 'services';
import { CreateUserDto } from 'types';
import { AUTH_OPTION_TYPES, MICROSERVICES } from 'utils';
import { createUserInitialValues, updateUserInitialValues } from 'utils/initial-values';
import { userValidationRules } from 'utils/validation-rules';
export type UserFormProps = {
  onChangeLoading?: (value: boolean) => void;
  onSubmitSuccess?: () => void;
  userId?: number;
};

export type UserFormRefProps = {
  form: FormInstance<CreateUserDto>;
  isLoading: boolean;
};

export type UserFormType = CreateUserDto & {
  confirmPassword?: string;
};
const UserForm = forwardRef<UserFormRefProps, UserFormProps>(({ onChangeLoading, onSubmitSuccess, userId }, ref) => {
  useImperativeHandle(ref, () => ({
    form: form,
    isLoading: isLoadingCreate || isLoadingUpdate
  }));
  const [onCreate, { isLoading: isLoadingCreate }] = useCreateUserMutation();
  const [onUpdate, { isLoading: isLoadingUpdate }] = useUpdateUserMutation();

  const [form] = Form.useForm<UserFormType>();

  useEffect(() => {
    if (onChangeLoading) {
      onChangeLoading(isLoadingCreate || isLoadingUpdate);
    }
  }, [onChangeLoading, isLoadingCreate, isLoadingUpdate]);

  const onFinish = ({ ...values }: UserFormType) => {
    const data: CreateUserDto = {
      ...values
    };
    if (!userId) {
      onCreate(data)
        .unwrap()
        .then((rs) => {
          notification.success({
            message: rs.message
          });
          onSubmitSuccess?.();
        });
    } else {
      onUpdate({
        userId,
        ...data
      })
        .unwrap()
        .then((rs) => {
          notification.success({
            message: rs.message
          });
          onSubmitSuccess?.();
        });
    }
  };

  return (
    <Form
      labelAlign='right'
      labelCol={{
        flex: '180px'
      }}
      requiredMark={false}
      form={form}
      name='userForm'
      onFinish={onFinish}
      layout='horizontal'
      validateMessages={validateMessages}
      initialValues={userId ? updateUserInitialValues : createUserInitialValues}
    >
      <Spin spinning={isLoadingCreate}>
        <FormItem.FloatLabel<UserFormType>
          name='employeeId'
          label={usersMessages.employeeId}
          rules={userValidationRules.employeeId}
        >
          <Input />
        </FormItem.FloatLabel>

        <FormItem.FloatLabel<UserFormType>
          name='fullName'
          label={usersMessages.fullName}
          rules={userValidationRules.fullName}
        >
          <Input />
        </FormItem.FloatLabel>

        <FormItem.FloatLabel<UserFormType>
          name='shortName'
          label={usersMessages.shortName}
          rules={userValidationRules.genderId}
        >
          <Input />
        </FormItem.FloatLabel>

        <FormItem.FloatLabel<UserFormType>
          name='genderId'
          label={usersMessages.gender}
          rules={userValidationRules.genderId}
        >
          <SelectOptions service={MICROSERVICES.AUTH} optionTypeId={AUTH_OPTION_TYPES.GENDER} />
        </FormItem.FloatLabel>

        <FormItem.FloatLabel<UserFormType>
          name='username'
          label={usersMessages.userName}
          rules={userValidationRules.username}
        >
          <Input />
        </FormItem.FloatLabel>

        <FormItem.FloatLabel<UserFormType>
          name='password'
          label={usersMessages.newPassword}
          rules={userValidationRules.password}
          hasFeedback
        >
          <Input.Password />
        </FormItem.FloatLabel>

        <FormItem.FloatLabel<UserFormType>
          name='confirmPassword'
          label={usersMessages.confirmPassword}
          rules={userValidationRules.confirmPassword}
          dependencies={['password']}
          hasFeedback
        >
          <Input.Password />
        </FormItem.FloatLabel>

        <FormItem.FloatLabel<UserFormType> label={usersMessages.email} rules={userValidationRules.email} name='email'>
          <Input />
        </FormItem.FloatLabel>

        <FormItem.FloatLabel<UserFormType>
          name='phoneNumber'
          label={usersMessages.phoneNumber}
          rules={userValidationRules.phoneNumber}
        >
          <Input />
        </FormItem.FloatLabel>

        <FormItem.FloatLabel<UserFormType>
          name='organizationUnitId'
          label={usersMessages.organize}
          rules={userValidationRules.organizationUnitId}
        >
          <SelectOrganizationUnits />
        </FormItem.FloatLabel>

        <FormItem.FloatLabel<UserFormType>
          name='userGroupId'
          label={usersMessages.group}
          rules={userValidationRules.userGroupId}
        >
          <SelectUserGroups allowClear />
        </FormItem.FloatLabel>

        <FormItem.FloatLabel<UserFormType>
          name='permissionIds'
          label={usersMessages.permissions}
          rules={userValidationRules.permissionIds}
        >
          <SelectPermissions mode='multiple' allowClear />
        </FormItem.FloatLabel>

        <FormItem.FloatLabel<UserFormType>
          name='roleIds'
          label={usersMessages.roles}
          rules={userValidationRules.roleIds}
        >
          <SelectRolesOptions mode='multiple' allowClear />
        </FormItem.FloatLabel>
        <FormItem.FloatLabel<UserFormType>
          name='statusId'
          label={usersMessages.status}
          rules={userValidationRules.statusId}
        >
          <SelectOptions service={MICROSERVICES.AUTH} optionTypeId={AUTH_OPTION_TYPES.USER_STATUS} />
        </FormItem.FloatLabel>
        {/**    
       
  

        
        <FormItem.FloatLabel<UserFormType>
          name='birthday'
          label={usersMessages.birthday}
          rules={userValidationRules.birthday}
        >
        disabled dưới 18 tuổi 
          <DatePicker
            className='w-full'
            disabledDate={disabledDateUnderYear(18)}
            defaultPickerValue={dayjs().subtract(18, 'year')}
          />
        </FormItem.FloatLabel> */}
      </Spin>
    </Form>
  );
});
export default UserForm;
