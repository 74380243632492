import { List, Modal, ModalProps, Skeleton, Spin, Typography } from 'antd';
import { Empty } from 'components/common';
import dayjs from 'dayjs';
import { useAllShiftsOptions } from 'hooks';
import { groupBy } from 'lodash';
import { shiftsMessages } from 'messages';
import { useSelector } from 'react-redux';
import { useActiveShiftMutation } from 'services';
import { RootState } from 'store';
import { twMerge } from 'tailwind-merge';
import { TIME_FORMAT, WEEKDAY_FORMAT } from 'utils';
type ActiveShiftsModalProps = ModalProps & {};
const ActiveShiftsModal = ({ open, onCancel, ...props }: ActiveShiftsModalProps) => {
  const { loadingShiftMeActive } = useSelector((state: RootState) => state.shifts);

  const { shiftsOptions, isLoading } = useAllShiftsOptions({
    nowTime: dayjs().format(TIME_FORMAT)
  });

  const [activeShift, { isLoading: isLoadingActive }] = useActiveShiftMutation();
  const groupedShifts = groupBy(shiftsOptions, (o) => dayjs(o.fromDateTime).format(WEEKDAY_FORMAT));
  const handleActiveShift = (shiftId: number) => {
    activeShift({
      shiftId,
      nowTime: dayjs().format(TIME_FORMAT)
    });
  };
  return (
    <Modal
      maskClosable={false}
      centered
      width={688}
      title={shiftsMessages.activeShift}
      open={open}
      onCancel={onCancel}
      destroyOnClose
      footer={null}
      closable={false}
    >
      {isLoading ? (
        <Skeleton
          className='w-full'
          title={false}
          paragraph={{
            rows: 5,
            width: '100%'
          }}
        />
      ) : (
        <Spin spinning={isLoadingActive || loadingShiftMeActive}>
          {Object.keys(groupedShifts).map((date) => (
            <div key={date}>
              <Typography.Title level={4}>{date}</Typography.Title>
              <List
                dataSource={groupedShifts[date]}
                locale={{
                  emptyText: <Empty />
                }}
                renderItem={(shift) => (
                  <List.Item
                    onClick={() => shift.isValid && handleActiveShift(shift.shiftId)}
                    key={shift.shiftId}
                    className={twMerge(
                      'mt-4 cursor-pointer rounded-base px-4 transition-all hover:border-transparent hover:bg-colorBgIconHover',
                      !shift.isValid &&
                        'cursor-not-allowed bg-colorBgContainerDisabled hover:bg-colorBgContainerDisabled'
                    )}
                  >
                    <List.Item.Meta
                      title={`${shift.code} - ${shift.name}`}
                      description={`${shiftsMessages.time}: ${shift.fromTime} - ${shift.toTime}`}
                    />
                  </List.Item>
                )}
              />
            </div>
          ))}
        </Spin>
      )}
    </Modal>
  );
};
export default ActiveShiftsModal;
