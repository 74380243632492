import { Button, Divider, List, Modal, Skeleton, Typography } from 'antd';
import { Avatar, Empty } from 'components/common';
import { messages, userGroupsMessages } from 'messages';
import { useRef, useState } from 'react';
import { useGetUserGroupDetailQuery } from 'services';
import UserGroupUpdateUsersForm, { UserGroupUpdateUsersFormRefProps } from './UserGroupUpdateUsersForm';
type UserGroupUsersTabProp = {
  userGroupId: number;
  openUpdate: boolean;
  setOpenUpdate: (val: boolean) => void;
};
const UserGroupUsersTab = ({ userGroupId, openUpdate, setOpenUpdate }: UserGroupUsersTabProp) => {
  const { data: userGroupRes, isLoading } = useGetUserGroupDetailQuery(userGroupId!, {
    skip: !userGroupId
  });
  const userGroup = userGroupRes?.data;

  const [loading, setLoading] = useState(false);

  const userGroupUsersFormRef = useRef<UserGroupUpdateUsersFormRefProps>(null);

  if (isLoading)
    return (
      <div>
        <Skeleton />
        <Divider className='my-4' />
        <Skeleton />
      </div>
    );
  return (
    <div>
      <List
        itemLayout='horizontal'
        dataSource={userGroup?.users}
        rowKey={(item) => item.userId}
        locale={{
          emptyText: <Empty />
        }}
        renderItem={(item, index) => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar name={item.shortName} fileId={item.avatar} />}
              title={<Typography.Text>{item.shortName}</Typography.Text>}
              description={<Typography.Text>{item.fullName}</Typography.Text>}
            />
          </List.Item>
        )}
      />
      <Modal
        maskClosable={false}
        centered
        width={688}
        title={userGroupsMessages.updateUsers}
        open={openUpdate}
        onCancel={() => {
          setOpenUpdate(false);
        }}
        footer={[
          <Button
            loading={loading}
            onClick={() => {
              if (userGroupUsersFormRef.current) {
                userGroupUsersFormRef.current.form.submit();
              }
            }}
            size='large'
            type='primary'
          >
            {messages.saveButtonText}
          </Button>
        ]}
      >
        <UserGroupUpdateUsersForm
          onChangeSuccess={() => {
            setOpenUpdate(false);
          }}
          ref={userGroupUsersFormRef}
          userGroupId={userGroupId}
          onChangeLoading={setLoading}
        />
      </Modal>
    </div>
  );
};
export default UserGroupUsersTab;
