import { useState } from 'react';
import { useGetVipCustomersQuery } from 'services';
import { FindAllVipCustomerDto } from 'types';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from 'utils';
const pageSize = DEFAULT_PAGE_SIZE;
export function useVipCustomersPaging({ keyword }: Omit<FindAllVipCustomerDto, 'pageIndex' | 'pageSize'>) {
  const [pageIndex, setPageIndex] = useState(DEFAULT_PAGE_INDEX);
  const {
    data: vipCustomersResponse,
    error,
    isLoading,
    isFetching
  } = useGetVipCustomersQuery({
    pageIndex: pageIndex,
    pageSize,
    keyword
  });
  const handleChangePage = (newPageIndex: number) => {
    setPageIndex(newPageIndex);
  };

  return {
    vipCustomers: vipCustomersResponse?.data.rows,
    count: vipCustomersResponse ? vipCustomersResponse.data.count : 0,
    pageIndex,
    isLoading: isFetching || isLoading,
    error,
    handleChangePage
  };
}
