import { GetProp, Upload as UploadAntd } from 'antd';
import { UploadFile, UploadProps as UploadPropsAntd } from 'antd/es/upload';
import type { UploadRequestOption as RcCustomRequestOptions, UploadRequestError } from 'rc-upload/lib/interface';
import React from 'react';
import { useDeleteFileMutation, useUploadFileMutation } from 'services';
import { FileDto } from 'types';
import { FilesBucketNames } from 'utils';
import { File } from '../files';
type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

type UploadProps = UploadPropsAntd & {
  bucketName: FilesBucketNames;
  onUploadSuccess?: (value: FileDto) => void;
};
const Upload = ({ bucketName, onUploadSuccess, ...props }: UploadProps) => {
  const fileList = props.fileList ? (Array.isArray(props.fileList) ? props.fileList : [props.fileList]) : undefined;

  const [onUpload] = useUploadFileMutation();
  const [onDelete] = useDeleteFileMutation();

  const customRequest = async (options: RcCustomRequestOptions) => {
    const { onError, onSuccess, onProgress, file } = options;
    try {
      onUpload({
        file,
        bucketName,
        onUploadProgress: (progress) => {
          console.log(progress.progress);
          onProgress?.({
            percent: progress?.progress ?? 0 * 100
          });
        }
      })
        .unwrap()
        .then((rs) => {
          onSuccess?.(rs.data);
        });
    } catch (error) {
      onError?.(error as UploadRequestError);
    }
  };
  const onRemove = async (file: UploadFile<FileDto> | number) => {
    const fileId = typeof file === 'number' ? file : file.response?.fileId;
    if (fileId) {
      await onDelete(fileId)
        .unwrap()
        .then((rs) => {
          return true;
        });
    }
    return true;
  };

  const onPreview = async (file: UploadFile<FileDto>) => {
    let src = file.response?.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as FileType);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  return (
    <UploadAntd
      onRemove={onRemove}
      onPreview={onPreview}
      itemRender={(
        _origin: React.ReactElement,
        file: UploadFile<FileDto> | number,
        _fileList: UploadFile<FileDto>[] | number[],
        actions
      ) => {
        return (
          <File
            className='h-full w-full'
            fileId={typeof file === 'number' ? file : file.response?.fileId}
            percent={typeof file == 'number' ? 0 : file.percent}
            isError={typeof file == 'number' ? false : file.status === 'error'}
            remove={() => {
              actions.remove();
            }}
          />
        );
      }}
      {...props}
      fileList={fileList || undefined}
      customRequest={customRequest}
    />
  );
};

export default Upload;
