import { Button, Form, FormInstance, List, Spin, Typography } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { Empty } from 'components/common';
import { FormItem } from 'components/common/form-item';
import { SelectPermissions } from 'components/permissions';
import { messages, permissionsMessages } from 'messages';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useGetUserGroupDetailQuery, useUpdateUserGroupMutation } from 'services';
import { PermissionCompactDto } from 'types';

export type UserGroupUpdatePermissionsFormProps = {
  onChangeLoading?: (value: boolean) => void;
  onChangeSuccess?: () => void;
  userGroupId: number;
};

export type UserGroupUpdatePermissionsFormRefProps = {
  form: FormInstance<UserGroupUpdatePermissionsFormType>;
  isLoading: boolean;
};
export type UserGroupUpdatePermissionsFormType = {
  permissions: PermissionCompactDto[];
};
const UserGroupUpdatePermissionsForm = forwardRef<
  UserGroupUpdatePermissionsFormRefProps,
  UserGroupUpdatePermissionsFormProps
>(({ userGroupId, onChangeLoading, onChangeSuccess }, ref) => {
  useImperativeHandle(ref, () => ({
    form: form,
    isLoading: isLoadingUpdate
  }));

  const { data: userGroupRes, isLoading } = useGetUserGroupDetailQuery(userGroupId!, {
    skip: !userGroupId
  });
  const userGroup = userGroupRes?.data;

  const [form] = Form.useForm<UserGroupUpdatePermissionsFormType>();
  const [onUpdate, { isLoading: isLoadingUpdate }] = useUpdateUserGroupMutation();
  const onFinish = (values: UserGroupUpdatePermissionsFormType) => {
    onUpdate({
      userGroupId,
      permissionIds: values.permissions.map((o) => o.permissionId)
    })
      .unwrap()
      .then(() => {
        onChangeSuccess?.();
      });
  };
  useEffect(() => {
    if (userGroup && userGroupId) {
      form.setFieldsValue({
        permissions: userGroup.permissions
      });
    }
  }, [userGroup?.permissions, userGroupId]);

  useEffect(() => {
    if (onChangeLoading) {
      onChangeLoading(isLoadingUpdate);
    }
  }, [onChangeLoading, isLoadingUpdate]);

  const permissions: PermissionCompactDto[] = Form.useWatch('permissions', form);
  const handleRemove = (record: PermissionCompactDto) => {
    form.setFieldValue(
      'permissions',
      permissions.filter((o) => o.permissionId !== record.permissionId)
    );
  };

  const handleAdd = (record: DefaultOptionType & PermissionCompactDto) => {
    form.setFieldValue('permissions', [
      {
        permissionId: record.permissionId,
        name: record.name,
        code: record.code
      },
      ...permissions.filter((o) => o.permissionId !== record.permissionId)
    ]);
  };
  return (
    <Form
      labelAlign='right'
      labelCol={{
        flex: '150px'
      }}
      requiredMark={false}
      form={form}
      name='resetPassword'
      onFinish={onFinish}
      layout='horizontal'
    >
      <Spin spinning={isLoading}>
        <FormItem<UserGroupUpdatePermissionsFormType> name='permissions' noStyle />
        <SelectPermissions
          onSelect={(_value, record) => {
            const item = record as DefaultOptionType & PermissionCompactDto;
            handleAdd(item);
          }}
          placeholder={permissionsMessages.name}
          size='large'
          className='mb-6 w-full'
          value={null}
        />
        <List
          itemLayout='horizontal'
          dataSource={permissions}
          rowKey={(item) => item.permissionId!}
          locale={{
            emptyText: <Empty />
          }}
          renderItem={(item, index) => (
            <List.Item
              actions={[
                <Button
                  danger
                  type='text'
                  size='small'
                  onClick={() => {
                    handleRemove(item);
                  }}
                  className='text-sm'
                >
                  {messages.deleteButtonText}
                </Button>
              ]}
            >
              <Typography.Text>{item.name}</Typography.Text>
            </List.Item>
          )}
        />
      </Spin>
    </Form>
  );
});
export default UserGroupUpdatePermissionsForm;
