import { useState } from 'react';
import { useGetServiceTypesOptionsQuery } from 'services';
import { FindAllServiceTypeDto } from 'types';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from 'utils';
const pageSize = DEFAULT_PAGE_SIZE;
export function useServiceTypesOptions({ isActive, keyword }: Omit<FindAllServiceTypeDto, 'pageIndex' | 'pageSize'>) {
  const [pageIndex, setPageIndex] = useState(DEFAULT_PAGE_INDEX);
  const { data: dataResponse, isLoading } = useGetServiceTypesOptionsQuery({
    keyword,
    isActive,
    pageIndex: pageIndex,
    pageSize
  });

  const { rows, count } = dataResponse?.data || {};

  const handleLoadMore = () => {
    if (rows && count && rows.length < count) {
      setPageIndex((prev) => prev + 1);
    }
  };

  return {
    serviceTypesOptions: rows || [],
    count: count || 0,
    isLoading,
    handleLoadMore
  };
}
