import { useState } from 'react';
import { useGetRolesQuery } from 'services';
import { FindAllRoleDto } from 'types';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from 'utils';
const pageSize = DEFAULT_PAGE_SIZE;
export function useRolesPaging({ keyword }: Omit<FindAllRoleDto, 'pageIndex' | 'pageSize'>) {
  const [pageIndex, setPageIndex] = useState(DEFAULT_PAGE_INDEX);
  const {
    data: rolesResponse,
    error,
    isLoading,
    isFetching
  } = useGetRolesQuery({
    keyword,
    pageIndex: pageIndex,
    pageSize
  });
  const handleChangePage = (newPageIndex: number) => {
    setPageIndex(newPageIndex);
  };

  return {
    roles: rolesResponse?.data.rows || [],
    count: rolesResponse ? rolesResponse.data.count : 0,
    isLoading: isLoading || isFetching,
    error,
    pageIndex,
    handleChangePage
  };
}
