import { Layout, Spin } from 'antd';
import { ActiveShiftsModal, Header, Loadable, SidebarMenu, SidebarSubmenu } from 'components';
import { useGetShiftMeActive } from 'hooks';
import { shiftsMessages } from 'messages';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { ROUTE } from 'routes/constants';
import { RootState } from 'store';
import { LOCAL_STORAGE_KEY } from 'utils';

const { Sider } = Layout;

function MainLayout() {
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  const { shiftMeActive, isLoading: isLoadingShiftMeActive } = useGetShiftMeActive();

  if (!localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN) || !isAuthenticated) {
    return <Navigate to={ROUTE.LOGIN} replace />;
  }
  return (
    <Spin tip={shiftsMessages.loadShift} spinning={isLoadingShiftMeActive}>
      <Layout>
        <Sider className='z-10' width={128}>
          <Layout className='sider-container'>
            <SidebarMenu />
            <SidebarSubmenu />
          </Layout>
        </Sider>
        <Layout>
          <Header />
          <Layout className='h-[calc(100vh-64px)] max-h-[calc(100vh-64px)] overflow-y-auto px-8 py-6'>
            <Loadable>
              <Outlet />
            </Loadable>
          </Layout>
        </Layout>
        <ActiveShiftsModal open={!isLoadingShiftMeActive && !shiftMeActive} onCancel={() => {}} />
      </Layout>
    </Spin>
  );
}

export default MainLayout;
