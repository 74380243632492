import { Typography } from 'antd';
import { maxBy } from 'lodash';
import { FixedSizeGrid as Grid } from 'react-window';

type GridProps = React.ComponentProps<typeof Grid>;

export type ExcelProps = Partial<Pick<GridProps, 'columnWidth' | 'rowHeight' | 'width' | 'height'>> & {
  data?: (string | number)[][];
};
const Excel = ({ data, columnWidth = 200, height = 400, rowHeight = 35, width = 800 }: ExcelProps) => {
  if (!data) return null;
  const Cell = ({ columnIndex, rowIndex, style }: any) => (
    <div
      className='flex items-center justify-center overflow-hidden text-ellipsis whitespace-nowrap border border-gray-300'
      style={style}
    >
      <Typography.Paragraph
        className='mb-0'
        ellipsis={{
          rows: 1,
          tooltip: true
        }}
      >
        {data[rowIndex][columnIndex]}
      </Typography.Paragraph>
    </div>
  );
  const maxColumn = maxBy(data, (o) => o.length)?.length || 1;
  return (
    <div>
      {data.length > 0 && (
        <Grid
          columnCount={maxColumn}
          rowCount={data.length}
          columnWidth={columnWidth}
          rowHeight={rowHeight}
          width={width}
          height={height}
        >
          {Cell}
        </Grid>
      )}
    </div>
  );
};
export default Excel;
