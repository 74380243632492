export const organizationUnitsMessages = {
  name: 'Đơn vị/ phòng đội',
  organizationUnitId: 'Mã quản lý',
  organizationUnitName: 'Tên ',
  organizationUnitQuantity: 'Số lượng',
  createNew: 'Tạo mới phòng đội',
  hasParent: 'Thuộc đơn vị/phòng đội cấp cao hơn',
  upperOrganizationUnit: 'Đơn vị cấp cao hơn',
  description: 'Mô tả',
  shortName: 'Tên thu gọn',
  service: 'Dịch vụ'
};
