import { PlusOutlined } from '@ant-design/icons';
import { Button, Typography, UploadFile } from 'antd';
import { FormItem, UploadExcel } from 'components';
import { UploadExcelDataType } from 'components/common/excel/UploadExcel';
import { messages } from 'messages';
import { customerSupportInformationMessages } from 'messages/customer-support-information.messages';
import { CreateCustomerSupportInformationDto } from 'types';
import { customerSupportInformationValidationRules } from 'utils';

const CustomerSupportInformationContentFormatTable = () => {
  const normFileExcel = ({ file }: { file: UploadFile<UploadExcelDataType> }) => {
    if (file.percent === 100 && file.response) return JSON.stringify(file.response?.excelData);
    return file;
  };

  return (
    <>
      <Typography.Title level={5} className='mb-4'>
        {customerSupportInformationMessages.uploadExcelTable}
      </Typography.Title>
      <FormItem<CreateCustomerSupportInformationDto>
        valuePropName='fileList'
        name='content'
        getValueFromEvent={normFileExcel}
        rules={customerSupportInformationValidationRules.content}
      >
        <UploadExcel
          maxCount={1}
          excelProps={{
            width: 848
          }}
        >
          <Button className='mb-4' icon={<PlusOutlined />}>
            {messages.upload}
          </Button>
        </UploadExcel>
      </FormItem>
    </>
  );
};
export default CustomerSupportInformationContentFormatTable;
