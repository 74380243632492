import { Form } from 'antd';
import FormItemErrorText from './FormItemErrorText';
import FormItemFloatLabel from './FormItemFloatLabel';

const { Item: ItemAntd } = Form;
export type FormItemProps = typeof ItemAntd & {
  FloatLabel: typeof FormItemFloatLabel;
  ErrorText: typeof FormItemErrorText;
};

const FormItem = ItemAntd as FormItemProps;
FormItem.FloatLabel = FormItemFloatLabel;
FormItem.ErrorText = FormItemErrorText;
export { FormItem };
