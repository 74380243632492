import OriginAvatar from './Avatar';
import AvatarUploadCrop from './UploadCrop';

export type AvatarProps = typeof OriginAvatar & {
  UploadCrop: typeof AvatarUploadCrop;
};
const Avatar = OriginAvatar as AvatarProps;

Avatar.UploadCrop = AvatarUploadCrop;

export { Avatar };
