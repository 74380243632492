import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetShiftMeActiveQuery } from 'services';
import { RootState } from 'store';
import { setAuthenticated } from 'store/features/auth/auth.slice';
import { clearUserProfile } from 'store/features/users/users.slice';

export function useGetShiftMeActive() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);

  const { error, isError, refetch } = useGetShiftMeActiveQuery(undefined, {
    skip: !isAuthenticated
  });

  const shiftMeActive = useSelector((state: RootState) => state.shifts.shiftMeActive);
  const isLoading = useSelector((state: RootState) => state.shifts.loadingShiftMeActive);

  useEffect(() => {
    if (error) {
      dispatch(setAuthenticated(false));
      dispatch(clearUserProfile());
    }
  }, [error]);

  return {
    shiftMeActive,
    isLoading,
    isAuthenticated,
    isError,
    error
  };
}
