import { useState } from 'react';
import { useGetPermissionsQuery } from 'services';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from 'utils';
import { FindAllPermissionDto } from './../../types/dto/permissions/find-all-permission.dto';
const pageSize = DEFAULT_PAGE_SIZE;
export function usePermissionsPaging({ keyword }: Omit<FindAllPermissionDto, 'pageIndex' | 'pageSize'>) {
  const [pageIndex, setPageIndex] = useState(DEFAULT_PAGE_INDEX);
  const {
    data: permissionsResponse,
    error,
    isLoading,
    isFetching
  } = useGetPermissionsQuery({
    keyword,
    pageIndex: pageIndex,
    pageSize
  });
  const handleChangePage = (newPageIndex: number) => {
    setPageIndex(newPageIndex);
  };

  return {
    permissions: permissionsResponse?.data.rows || [],
    count: permissionsResponse ? permissionsResponse.data.count : 0,
    pageIndex,
    isLoading: isLoading || isFetching,
    error,
    handleChangePage,
    keyword
  };
}
