export * from './item-select-all.constant';
export * from './local-storage-key.constant';
export * from './service-types-form.constant';
export * from './sidebar-menu.constant';

export const DEFAULT_PAGE_INDEX = 1;
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PASSWORD_CHART = '********';
export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_TIME_FORMAT = 'HH:mm DD/MM/YYYY';
export const WEEKDAY_FORMAT = 'ddd, MM/MM/YYYY';
export const TIME_FORMAT = 'HH:mm';
export const TIME_STAMP_FORMAT = 'HH:mm:ss';
export const ID_CRM_HEADER = 'crm-header';
export const ID_PAGE_HEADER = 'page-header';
