export const vipCustomersMessages = {
  create: 'Tạo mới khách hàng vip',
  update: 'Cập nhật khách hàng vip',
  code: 'Mã khách hàng VIP',
  name: 'Tên khách hàng VIP',
  note: 'Ghi chú',
  color: 'Màu sắc đại diện cho khách hàng VIP',
  phoneNumber: 'Số điện thoại',
  delete: 'Xóa khách hàng VIP',
  confirmDelete: 'Bạn có chắc chắn muốn xóa khách hàng VIP này không?'
};
