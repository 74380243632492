import { useState } from 'react';
import { useGetUserGroupsQuery } from 'services/user-groups';
import { FindAllUserGroupDto } from 'types';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from 'utils';
const pageSize = DEFAULT_PAGE_SIZE;
export function useUserGroupsPaging({
  keyword,
  organizationUnitId
}: Omit<FindAllUserGroupDto, 'pageIndex' | 'pageSize'>) {
  const [pageIndex, setPageIndex] = useState(DEFAULT_PAGE_INDEX);

  const {
    data: userGroupsResponse,
    error,
    isLoading,
    isFetching
  } = useGetUserGroupsQuery({
    pageIndex: pageIndex,
    pageSize,
    keyword,
    organizationUnitId
  });
  const handleChangePage = (newPageIndex: number) => {
    setPageIndex(newPageIndex);
  };

  return {
    userGroups: userGroupsResponse?.data.rows,
    count: userGroupsResponse ? userGroupsResponse.data.count : 0,
    pageIndex,
    isLoading: isLoading || isFetching,
    error,

    handleChangePage
  };
}
