import { Button, Layout, Menu } from 'antd';
import { logo, LogoutIcon } from 'assets';
import { Avatar } from 'components/common';
import { useActiveMenu, useLogout } from 'hooks';
import { find } from 'lodash';
import { useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { ROUTE } from 'routes/constants';
import { RootState } from 'store';
import { twMerge } from 'tailwind-merge';
import { SidebarMenuItems } from 'utils';

const { Sider } = Layout;

const SidebarMenu = () => {
  const navigate = useNavigate();
  const { selectedMenu } = useActiveMenu();

  const { isLoading, onLogoutHandle } = useLogout();
  const profile = useSelector((state: RootState) => state.users.userProfile);
  return (
    <Sider collapsed collapsedWidth={72} className='left-column h-svh bg-sidebar-bg px-2 pt-4'>
      <div className='flex h-full flex-col items-center pb-[72px]'>
        <div className='mb-6 flex justify-center'>
          <img src={logo} alt='Logo' className='h-10 w-10' />
        </div>
        <Menu
          selectedKeys={selectedMenu ? [selectedMenu] : []}
          className='bg-transparent'
          theme='dark'
          mode='inline'
          defaultSelectedKeys={['1']}
          items={SidebarMenuItems.map((menu) => ({
            icon: menu.icon,
            label: menu.name,
            key: menu.key
          }))}
          onSelect={(item) => {
            const featureSelect = find(SidebarMenuItems, {
              key: item.key
            });
            if (featureSelect) navigate(featureSelect.href);
          }}
        />
        <NavLink
          to={ROUTE.PROFILE}
          className='mt-auto flex h-14 w-14 items-center justify-center rounded-base hover:bg-spotlightHover'
        >
          <Avatar size={48} fileId={profile?.avatar} name={profile?.fullName} />
        </NavLink>
        <Button
          loading={isLoading}
          ghost
          type='text'
          onClick={() => onLogoutHandle()}
          className={twMerge('mt-4 h-14 w-14 rounded-base text-white hover:bg-spotlightHover')}
          icon={<LogoutIcon width={32} height={32} />}
        />
      </div>
    </Sider>
  );
};

export default SidebarMenu;
