import { useState } from 'react';
import { useGetUsersOptionsQuery } from 'services';
import { FindAllUserDto } from 'types';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from 'utils';
const pageSize = DEFAULT_PAGE_SIZE;
export function useUsersOptions({
  organizationUnitId,
  statusId,
  userGroupId,
  keyword
}: Omit<FindAllUserDto, 'pageIndex' | 'pageSize'>) {
  const [pageIndex, setPageIndex] = useState(DEFAULT_PAGE_INDEX);
  const { data: dataResponse, isLoading } = useGetUsersOptionsQuery({
    keyword,
    organizationUnitId,
    statusId,
    userGroupId,
    pageIndex: pageIndex,
    pageSize
  });

  const { rows, count } = dataResponse?.data || {};

  const handleLoadMore = () => {
    if (rows && count && rows.length < count) {
      setPageIndex((prev) => prev + 1);
    }
  };

  return {
    usersOptions: rows || [],
    count: count || 0,
    isLoading,
    handleLoadMore
  };
}
