import { useState } from 'react';
import { useGetShiftsQuery } from 'services';
import { FindAllShiftDto } from 'types';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from 'utils';
const pageSize = DEFAULT_PAGE_SIZE;
export function useShiftsPaging({ keyword }: Omit<FindAllShiftDto, 'pageIndex' | 'pageSize'>) {
  const [pageIndex, setPageIndex] = useState(DEFAULT_PAGE_INDEX);
  const {
    data: shiftsResponse,
    error,
    isLoading,
    isFetching
  } = useGetShiftsQuery({
    pageIndex: pageIndex,
    pageSize,
    keyword
  });
  const handleChangePage = (newPageIndex: number) => {
    setPageIndex(newPageIndex);
  };

  return {
    shifts: shiftsResponse?.data.rows,
    count: shiftsResponse ? shiftsResponse.data.count : 0,
    pageIndex,
    isLoading: isFetching || isLoading,
    error,
    handleChangePage
  };
}
