const colorsBase = {
  // antd
  colorPrimary: '#1564E8',
  colorPrimaryActive: '#1564E8',
  colorPrimaryBg: '#ECF3FE',

  colorTextBase: '#181818',
  colorTextSecondary: '#878787',
  colorTextLabel: '#6C737E',

  colorTextPlaceholder: '#878787',

  colorBgBase: '#fff',
  colorBgContainer: '#fff',
  colorBgBody: '#F5F5F7',
  colorBgContainerDisabled: '#D6D8DB',

  colorError: '#D1131D',

  colorBgSpotlight: '#222A41',
  colorTextLightSolid: '#fff',
  colorBorder: '#DEE2E6',

  spotlightHover: '#ffffff3d',

  tailColor: '#B2B9C4',

  // new
  colorBgInput: '#F7F8F9',
  colorBorderInput: '#EBEBED',
  colorBgIconHover: '#F5F5F7',
  colorBgContainerHover: '#00000099',
  colorBgContainerAuth: '#0330b2'
};

const fonts = {
  fontSize: 16,
  lineHeight: 1.25,

  fontSizeHeading1: 38,
  lineHeightHeading1: 1.2105263157894737,

  fontSizeHeading2: 30,
  lineHeightHeading2: 1.2666666666666666,

  fontSizeHeading3: 24,
  lineHeightHeading3: 1.3333333333333333,

  fontSizeHeading4: 20,
  lineHeightHeading4: 1.4,

  fontSizeHeading5: 16,
  lineHeightHeading5: 1.5
};
export { colorsBase, fonts };
