import { Filter } from 'components/common';
import { useOrganizationUnitsOptions } from 'hooks';
import { PropsWithChildren, useState } from 'react';
import { FindAllOrganizationUnitDto } from 'types';

type FilterOrganizationUnitsProps = PropsWithChildren &
  Omit<FindAllOrganizationUnitDto, 'keyword' | 'pageIndex' | 'pageSize'>;

const FilterOrganizationUnits = ({ parentId, ...props }: FilterOrganizationUnitsProps) => {
  const [keyword, setKeyword] = useState('');

  const { organizationsUnits, handleLoadMore, isLoading } = useOrganizationUnitsOptions({
    parentId,
    keyword
  });
  const handleSearch = (val: string) => {
    setKeyword(val);
  };

  return (
    <Filter.CheckboxSearch
      {...props}
      options={organizationsUnits.map((o) => ({
        label: o.name,
        value: o.organizationUnitId
      }))}
      onSearch={handleSearch}
      loadMore={handleLoadMore}
      title={'Đơn vị phòng đội'}
      loading={isLoading}
    />
  );
};
export default FilterOrganizationUnits;
