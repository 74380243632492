import { Select } from 'components/common';
import { SelectProps } from 'components/common/select/Select';
import { useServiceTypesOptions } from 'hooks';
import { useState } from 'react';
import { ITEM_SELECT_ALL } from 'utils';

type SelectServiceTypesProps = SelectProps & {
  isAll?: boolean;
  isActive?: boolean;
};

const SelectServiceTypes = ({ isAll = false, isActive, ...props }: SelectServiceTypesProps) => {
  const [keyword, setKeyword] = useState('');
  const { serviceTypesOptions, handleLoadMore, isLoading } = useServiceTypesOptions({
    isActive,
    keyword
  });
  const handleSearch = (val: string) => {
    setKeyword(val);
  };
  return (
    <Select
      {...props}
      onLoadMore={() => {
        if (!isLoading) {
          handleLoadMore();
        }
      }}
      loading={isLoading}
      showSearch
      filterOption={false}
      onSearch={handleSearch}
      options={[
        ...(isAll ? [ITEM_SELECT_ALL] : []),
        ...(serviceTypesOptions.map((itm) => ({
          label: itm.name,
          value: itm.serviceTypeId
        })) || [])
      ]}
    />
  );
};
export default SelectServiceTypes;
