import { Rule } from 'antd/es/form';
import { VipCustomerFormType } from 'components/vip-customers/VipCustomerForm';

export const vipCustomersValidationRules: Record<keyof VipCustomerFormType, Rule[]> = {
  code: [{ required: true }],
  name: [{ required: true }],
  color: [],
  note: [],
  phoneNumber: []
};
