import { PlusOutlined } from '@ant-design/icons';
import { Checkbox, Form, FormInstance, Input, notification, Space, Spin, Switch, Typography, UploadFile } from 'antd';
import { FormItem, SelectOptions, Upload } from 'components';
import { messages, validateMessages } from 'messages';
import { customerSupportInformationMessages } from 'messages/customer-support-information.messages';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import {
  useCreateCustomerSupportInformationMutation,
  useDeleteFileMutation,
  useGetCustomerSupportInformationDetailQuery,
  useUpdateCustomerSupportInformationMutation
} from 'services';
import { CreateCustomerSupportInformationDto, FileDto } from 'types';
import {
  CORE_OPTION_TYPES,
  createCustomerSupportInformationInitialValues,
  CUSTOMER_SUPPORT_INFORMATION_FORMAT,
  customerSupportInformationValidationRules,
  FilesBucketNames,
  MICROSERVICES,
  updateCustomerSupportInformationInitialValues
} from 'utils';
import CustomerSupportInformationContentFormatLink from './CustomerSupportInformationContentFormatLink';
import CustomerSupportInformationContentFormatTable from './CustomerSupportInformationContentFormatTable';
import CustomerSupportInformationContentFormatText from './CustomerSupportInformationContentFormatText';

export type CustomerSupportInformationFromProps = {
  onChangeLoading?: (value: boolean) => void;
  onCreateSuccess?: () => void;
  customerSupportInformationId?: number;
};
export type CustomerSupportInformationFormType = CreateCustomerSupportInformationDto;

export type CustomerSupportInformationFromRefProps = {
  form: FormInstance<CustomerSupportInformationFormType>;
  isLoading: boolean;
};

const CustomerSupportInformationForm = forwardRef<
  CustomerSupportInformationFromRefProps,
  CustomerSupportInformationFromProps
>(({ onChangeLoading, onCreateSuccess, customerSupportInformationId }, ref) => {
  useImperativeHandle(ref, () => ({
    form: form,
    isLoading: isLoadingCreate || isLoadingUpdate
  }));

  const [form] = Form.useForm<CustomerSupportInformationFormType>();
  const icon = Form.useWatch('icon', form);
  const { data: customerSupportInformation, isLoading: isLoadingDetail } = useGetCustomerSupportInformationDetailQuery(
    customerSupportInformationId!,
    {
      skip: !customerSupportInformationId,
      refetchOnMountOrArgChange: true
    }
  );

  useEffect(() => {
    if (customerSupportInformation && customerSupportInformationId) {
      form.setFieldsValue({
        ...customerSupportInformation.data
      });
    }
  }, [customerSupportInformation, customerSupportInformationId]);

  const [onCreate, { isLoading: isLoadingCreate }] = useCreateCustomerSupportInformationMutation();
  const [onUpdate, { isLoading: isLoadingUpdate }] = useUpdateCustomerSupportInformationMutation();
  const [onDeleteFile] = useDeleteFileMutation();

  const normFile = ({ file }: { file: UploadFile<FileDto> }) => {
    if (file.response?.fileId) return file.response.fileId;
    if (file.status === 'removed') return undefined;
    return file;
  };

  const onFinish = ({ ...values }: CustomerSupportInformationFormType) => {
    const data: CustomerSupportInformationFormType = {
      ...values
    };
    if (!customerSupportInformationId) {
      onCreate(data)
        .unwrap()
        .then((rs) => {
          notification.success({
            message: rs.message
          });
          onCreateSuccess?.();
        });
    } else {
      onUpdate({
        customerSupportInformationId,
        ...data
      })
        .unwrap()
        .then((rs) => {
          if (customerSupportInformation?.data.icon && customerSupportInformation?.data.icon !== data.icon) {
            onDeleteFile(customerSupportInformation?.data.icon).unwrap();
          }
          notification.success({
            message: rs.message
          });
          onCreateSuccess?.();
        });
    }
  };
  useEffect(() => {
    if (onChangeLoading) {
      onChangeLoading(isLoadingCreate || isLoadingUpdate);
    }
  }, [onChangeLoading, isLoadingCreate, isLoadingUpdate]);
  return (
    <Form
      scrollToFirstError={{ behavior: 'smooth', block: 'start' }}
      labelAlign='right'
      labelCol={{
        flex: '180px'
      }}
      requiredMark={false}
      form={form}
      name=''
      onFinish={onFinish}
      layout='horizontal'
      validateMessages={validateMessages}
      initialValues={
        customerSupportInformationId
          ? updateCustomerSupportInformationInitialValues
          : createCustomerSupportInformationInitialValues
      }
    >
      <Spin spinning={isLoadingCreate || isLoadingDetail || isLoadingUpdate}>
        <FormItem.FloatLabel<CustomerSupportInformationFormType>
          name='title'
          label={customerSupportInformationMessages.title}
          rules={customerSupportInformationValidationRules.title}
        >
          <Input />
        </FormItem.FloatLabel>
        <Typography.Title level={5}>{customerSupportInformationMessages.icon}</Typography.Title>
        <FormItem<CustomerSupportInformationFormType>
          valuePropName='fileList'
          name='icon'
          getValueFromEvent={normFile}
          rules={customerSupportInformationValidationRules.icon}
        >
          <Upload
            onRemove={() => true}
            accept='.jpeg, .png, .svg, .jpg'
            maxCount={1}
            bucketName={FilesBucketNames.PUBLIC}
            listType='picture-card'
          >
            {!icon && (
              <button style={{ border: 0, background: 'none' }} type='button'>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>{messages.upload}</div>
              </button>
            )}
          </Upload>
        </FormItem>

        <Form.Item<CustomerSupportInformationFormType>
          rules={customerSupportInformationValidationRules.isNew}
          name='isNew'
          valuePropName='checked'
        >
          <Checkbox>{customerSupportInformationMessages.isNew}</Checkbox>
        </Form.Item>

        <FormItem.FloatLabel<CustomerSupportInformationFormType>
          name='formatId'
          label={customerSupportInformationMessages.format}
          rules={customerSupportInformationValidationRules.formatId}
        >
          <SelectOptions
            optionTypeId={CORE_OPTION_TYPES.CUSTOMER_SUPPORT_INFORMATION_FORMAT}
            service={MICROSERVICES.CORE}
            onSelect={() => form.setFieldValue('content', undefined)}
          />
        </FormItem.FloatLabel>
        <Form.Item
          shouldUpdate={(prev: CustomerSupportInformationFormType, curr: CustomerSupportInformationFormType) =>
            prev.formatId !== curr.formatId
          }
          noStyle
        >
          {() => {
            const formatId: CUSTOMER_SUPPORT_INFORMATION_FORMAT = form.getFieldValue('formatId');
            switch (formatId) {
              case CUSTOMER_SUPPORT_INFORMATION_FORMAT.TEXT:
                return <CustomerSupportInformationContentFormatText form={form} initialValue={''} />;
              case CUSTOMER_SUPPORT_INFORMATION_FORMAT.EXCEL_TABLE:
                return <CustomerSupportInformationContentFormatTable />;
              default:
                return <CustomerSupportInformationContentFormatLink />;
            }
          }}
        </Form.Item>

        <Space align='center'>
          <Form.Item<CustomerSupportInformationFormType> noStyle name='isActive' valuePropName='checked'>
            <Switch />
          </Form.Item>
          <Typography.Text>{messages.statusEnum.active}</Typography.Text>
        </Space>
      </Spin>
    </Form>
  );
});
export default CustomerSupportInformationForm;
