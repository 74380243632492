export const usersMessages = {
  createUser: 'Tạo nhân viên',
  employeeId: 'Mã nhân viên',
  userName: 'Tên đăng nhập',
  fullName: 'Tên',
  gender: 'Giới tính',
  unit: 'Đơn vị/ phòng đội',
  group: 'Nhóm',
  organize: 'Phòng đội',
  permissions: 'Phân quyền',
  creationHistory: 'Lịch sử tạo phiếu',
  evaluationHistory: 'Lịch sử đánh giá',
  workingTime: 'Thời gian làm việc',
  currentPassword: 'Mật khẩu hiện tại',
  newPassword: 'Mật khẩu mới',
  confirmPassword: 'Xác nhận mật khẩu',
  email: 'Email',
  password: 'Mật khẩu',
  status: 'Trạng thái',
  shortName: 'Tên viết tắt',
  phoneNumber: 'Số điện thoại',
  birthday: 'Ngày sinh',
  personal: 'Cá nhân',
  roles: 'Nhóm quyền',
  avatar: 'Ảnh đại diên',
  changePassword: 'Đổi mật khẩu'
};
