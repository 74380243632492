import { Divider, Skeleton, Space, Typography } from 'antd';
import { useUserPermissions } from 'hooks';
import { usersMessages } from 'messages/users.messages';

type UserPermissionsTabProps = {
  userId: number;
};
const UserPermissionsTab = ({ userId }: UserPermissionsTabProps) => {
  const { userPermissions, isLoading } = useUserPermissions(userId);
  if (isLoading)
    return (
      <div>
        <Skeleton />
        <Divider className='my-4' />
        <Skeleton />
      </div>
    );
  return (
    <div>
      <Typography.Title className='mb-2' level={5}>
        {usersMessages.personal}
      </Typography.Title>
      <Space direction='vertical' size={8}>
        {userPermissions?.permissions.map((itm) => (
          <Typography.Text key={itm.permissionId}>{itm.name}</Typography.Text>
        ))}
      </Space>
      <Divider className='my-4' />
      <Typography.Title className='mb-2' level={5}>
        {userPermissions?.userGroup?.name}
      </Typography.Title>
      <Space direction='vertical' size={8}>
        {userPermissions?.userGroup?.permissions.map((itm) => (
          <Typography.Text key={itm.permissionId}>{itm.name}</Typography.Text>
        ))}
      </Space>
    </div>
  );
};
export default UserPermissionsTab;
