import { notification } from 'antd';
import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { ROUTE } from 'routes/constants';
import { ResponseSuccessDto, TokenDto } from 'types';
import { configuration } from './configuration';
import { LOCAL_STORAGE_KEY } from './constants';
import { HTTP_STATUS } from './enums';

let refreshingFunc: Promise<AxiosResponse<TokenDto>> | null = null;

export async function refreshTokenApi(token: string) {
  if (refreshingFunc) return refreshingFunc;

  refreshingFunc = axiosClient.post(
    '/auth/refresh',
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );

  return refreshingFunc;
}
export type AxiosRequestConfig = InternalAxiosRequestConfig<any> & {
  _retry?: boolean;
  _ignoreRefresh?: boolean;
  _ignoreNotificationError?: boolean;
};

const axiosClient = axios.create({
  baseURL: `${configuration.API_URL}/`,
  headers: {
    'Content-Type': 'application/json'
  }
});

axiosClient.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN);
      if (accessToken) config.headers!.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axiosClient.interceptors.response.use(
  (response: AxiosResponse) => response.data,
  async (error: AxiosError) => {
    const originalRequest = error.config as AxiosRequestConfig;
    if (
      error?.response?.status === HTTP_STATUS.UNAUTHORIZED &&
      originalRequest.url !== '/auth/refresh' &&
      !originalRequest?._ignoreRefresh &&
      !originalRequest?._retry
    ) {
      originalRequest._retry = true;

      const refreshToken = await localStorage.getItem(LOCAL_STORAGE_KEY.REFRESH_TOKEN);
      if (refreshToken) {
        try {
          const responseToken = await refreshTokenApi(refreshToken);
          if (responseToken.data) {
            localStorage.setItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN, responseToken.data.accessToken);
            localStorage.setItem(LOCAL_STORAGE_KEY.REFRESH_TOKEN, responseToken.data.refreshToken);
            originalRequest.headers.Authorization = `Bearer ${responseToken.data.accessToken}`;
            return axiosClient.request(originalRequest);
          }
        } catch (err) {
          localStorage.removeItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN);
          localStorage.removeItem(LOCAL_STORAGE_KEY.REFRESH_TOKEN);
          window.location.href = ROUTE.LOGIN;
        } finally {
          refreshingFunc = null;
        }
      }
    } else if (!originalRequest?._ignoreNotificationError) {
      const data = error?.response?.data as ResponseSuccessDto<null>;
      const errorMessage = data?.message?.toString() || error?.response?.statusText || error?.message;
      notification.error({ message: errorMessage, description: data?.errors?.join(', ') || '' });
    }

    return Promise.reject(error);
  }
);

export default axiosClient;
