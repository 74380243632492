import { Action, combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import {
  authApi,
  customerSupportInformationApi,
  filesApi,
  frequentlyAskedQuestionApi,
  optionsApi,
  organizationUnitsApi,
  permissionsApi,
  reasonsApi,
  rolesApi,
  serviceInstructionsApi,
  serviceTypesApi,
  shiftsApi,
  userGroupsApi,
  usersApi,
  vipCustomersApi,
  workingSchedulesApi
} from 'services';
import { authSlice, shiftsSlice, usersSlice } from './features';

const combinedReducer = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [organizationUnitsApi.reducerPath]: organizationUnitsApi.reducer,
  [userGroupsApi.reducerPath]: userGroupsApi.reducer,
  [permissionsApi.reducerPath]: permissionsApi.reducer,
  [optionsApi.reducerPath]: optionsApi.reducer,
  [filesApi.reducerPath]: filesApi.reducer,
  [rolesApi.reducerPath]: rolesApi.reducer,
  [customerSupportInformationApi.reducerPath]: customerSupportInformationApi.reducer,
  [serviceTypesApi.reducerPath]: serviceTypesApi.reducer,
  [frequentlyAskedQuestionApi.reducerPath]: frequentlyAskedQuestionApi.reducer,
  [serviceInstructionsApi.reducerPath]: serviceInstructionsApi.reducer,
  [workingSchedulesApi.reducerPath]: workingSchedulesApi.reducer,
  [shiftsApi.reducerPath]: shiftsApi.reducer,
  [reasonsApi.reducerPath]: reasonsApi.reducer,
  [vipCustomersApi.reducerPath]: vipCustomersApi.reducer,

  auth: authSlice,
  users: usersSlice,
  shifts: shiftsSlice
});
const rootReducer = (state: any, action: Action) => {
  if (action.type === 'RESET') {
    //We are calling this RESET, but call what you like!
    state = {};
  }
  return combinedReducer(state, action);
};
export const store = configureStore({
  reducer: rootReducer,
  // Adding the api middleware enables caching, invalidation, polling, and other features of RTK Query
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      usersApi.middleware,
      authApi.middleware,
      organizationUnitsApi.middleware,
      userGroupsApi.middleware,
      permissionsApi.middleware,
      optionsApi.middleware,
      filesApi.middleware,
      rolesApi.middleware,
      customerSupportInformationApi.middleware,
      serviceTypesApi.middleware,
      frequentlyAskedQuestionApi.middleware,
      serviceInstructionsApi.middleware,
      workingSchedulesApi.middleware,
      shiftsApi.middleware,
      reasonsApi.middleware,
      vipCustomersApi.middleware
    )
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
