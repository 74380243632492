import { DeleteFilled, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Progress, Spin, Typography } from 'antd';
import { useFile } from 'hooks/files';
import { PropsWithChildren, useCallback } from 'react';
import { twMerge } from 'tailwind-merge';
import { colorsBase } from 'themes';

type FileProps = PropsWithChildren & {
  fileId?: number;
  percent?: number;
  uploading?: boolean;
  isError?: boolean;
  className?: string;
  remove?: (fileId: number) => void;
};
const File = ({ fileId, percent, className, remove, isError, ...props }: FileProps) => {
  const { file, isLoading, isError: isErrorGet, error } = useFile(fileId);

  const renderFile = useCallback(() => {
    if (isError || isErrorGet)
      return (
        <div className='flex flex-col items-center justify-center gap-2'>
          <InfoCircleOutlined className='text-2xl text-colorError' />
          {error?.message && (
            <Typography.Text className='text-center text-xs' type='danger'>
              {error?.message}
            </Typography.Text>
          )}
        </div>
      );
    if (!file)
      return (
        <>
          <Spin spinning={isLoading} />
          <Progress size={'small'} strokeColor={colorsBase.colorPrimary} showInfo={false} percent={percent} />
        </>
      );
    const isImage = file.fileType.startsWith('image/');
    if (isImage) return <img className='h-full w-full object-contain' src={file.url} alt={file.objectName} />;
  }, [file, fileId, isErrorGet, isError, error]);

  const actions = useCallback(() => {
    if (fileId && remove)
      return (
        <div className='absolute inset-0 flex cursor-pointer items-center justify-center bg-colorBgContainerHover text-white opacity-0 transition-opacity duration-300 hover:opacity-100'>
          {remove && (
            <Button
              type='text'
              ghost
              className='h-6 w-6 text-white hover:bg-spotlightHover'
              icon={<DeleteFilled />}
              size='small'
              onClick={() => fileId && remove(fileId)}
            />
          )}
        </div>
      );
  }, [fileId]);

  return (
    <div
      className={twMerge('relative flex flex-col items-center justify-center gap-2 rounded-base border p-1', className)}
      key={fileId}
    >
      {renderFile()}
      {actions()}
    </div>
  );
};
export default File;
