import { useGetAllShiftsOptionsQuery } from 'services';
import { FindAllShiftOptionDto } from 'types';
export function useAllShiftsOptions({ nowTime }: FindAllShiftOptionDto) {
  const {
    data: shiftsOptionsResponse,
    error,
    isLoading,
    isFetching
  } = useGetAllShiftsOptionsQuery({
    nowTime
  });

  return {
    shiftsOptions: shiftsOptionsResponse?.data.rows,
    count: shiftsOptionsResponse ? shiftsOptionsResponse.data.count : 0,
    isLoading: isFetching || isLoading,
    error
  };
}
