import { Input } from 'antd';
import { FormItem } from 'components';
import { customerSupportInformationMessages } from 'messages/customer-support-information.messages';
import { CreateCustomerSupportInformationDto } from 'types';
import { customerSupportInformationValidationRules } from 'utils';

const CustomerSupportInformationContentFormatLink = () => {
  return (
    <FormItem.FloatLabel<CreateCustomerSupportInformationDto>
      name='content'
      label={customerSupportInformationMessages.content}
      rules={[
        ...customerSupportInformationValidationRules.content,
        {
          type: 'url'
        }
      ]}
    >
      <Input />
    </FormItem.FloatLabel>
  );
};
export default CustomerSupportInformationContentFormatLink;
