import { useState } from 'react';
import { useGetRolesOptionsQuery } from 'services';
import { FindAllRoleDto } from 'types';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from 'utils';
const pageSize = DEFAULT_PAGE_SIZE;
export function useRolesOptions({ keyword }: Omit<FindAllRoleDto, 'pageIndex' | 'pageSize'>) {
  const [pageIndex, setPageIndex] = useState(DEFAULT_PAGE_INDEX);
  const { data: dataResponse, isLoading } = useGetRolesOptionsQuery({
    keyword,
    pageIndex: pageIndex,
    pageSize
  });

  const { rows, count } = dataResponse?.data || {};

  const handleLoadMore = () => {
    if (rows && count && rows.length < count) {
      setPageIndex((prev) => prev + 1);
    }
  };

  return {
    rolesOptions: rows || [],
    count: count || 0,
    isLoading,

    handleLoadMore
  };
}
