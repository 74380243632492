import { useState } from 'react';
import { useGetOrganizationUnitsQuery } from 'services/organization-units';
import { FindAllOrganizationUnitDto } from 'types';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from 'utils';
const pageSize = DEFAULT_PAGE_SIZE;
export function useOrganizationUnitsPaging({
  keyword,
  parentId
}: Omit<FindAllOrganizationUnitDto, 'pageIndex' | 'pageSize'>) {
  const [pageIndex, setPageIndex] = useState(DEFAULT_PAGE_INDEX);
  const {
    data: organizationUnitsResponse,
    error,
    isLoading,
    isFetching
  } = useGetOrganizationUnitsQuery({
    pageIndex: pageIndex,
    pageSize,
    keyword,
    parentId
  });

  const handleChangePage = (newPageIndex: number) => {
    setPageIndex(newPageIndex);
  };

  return {
    organizationsUnits: organizationUnitsResponse?.data.rows || [],
    count: organizationUnitsResponse?.data.count || 0,
    pageIndex,
    isLoading: isFetching || isLoading,
    error,

    handleChangePage
  };
}
