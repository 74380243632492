import { Button, Divider, Modal, Skeleton, Space, Typography } from 'antd';
import { Empty } from 'components/common';
import { messages, userGroupsMessages } from 'messages';
import { useRef, useState } from 'react';
import { useGetUserGroupDetailQuery } from 'services';
import UserGroupUpdatePermissionsForm, {
  UserGroupUpdatePermissionsFormRefProps
} from './UserGroupUpdatePermissionsForm';
type UserGroupPermissionsTabProp = {
  userGroupId: number;
  openUpdate: boolean;
  setOpenUpdate: (val: boolean) => void;
};
const UserGroupPermissionsTab = ({ userGroupId, openUpdate, setOpenUpdate }: UserGroupPermissionsTabProp) => {
  const { data: userGroupRes, isLoading } = useGetUserGroupDetailQuery(userGroupId!, {
    skip: !userGroupId
  });
  const userGroup = userGroupRes?.data;

  const [loading, setLoading] = useState(false);

  const userGroupPermissionsFormRef = useRef<UserGroupUpdatePermissionsFormRefProps>(null);

  if (isLoading)
    return (
      <div>
        <Skeleton />
        <Divider className='my-4' />
        <Skeleton />
      </div>
    );
  return (
    <div>
      {userGroup?.permissions?.length && userGroup?.permissions?.length > 0 ? (
        <Space direction='vertical' size={8}>
          {userGroup?.permissions?.map((itm) => <Typography.Text key={itm.permissionId}>{itm.name}</Typography.Text>)}{' '}
        </Space>
      ) : (
        <Empty />
      )}

      <Modal
        maskClosable={false}
        centered
        width={688}
        title={userGroupsMessages.updatePermissions}
        open={openUpdate}
        onCancel={() => {
          setOpenUpdate(false);
        }}
        footer={[
          <Button
            loading={loading}
            onClick={() => {
              if (userGroupPermissionsFormRef.current) {
                userGroupPermissionsFormRef.current.form.submit();
              }
            }}
            size='large'
            type='primary'
          >
            {messages.saveButtonText}
          </Button>
        ]}
      >
        <UserGroupUpdatePermissionsForm
          onChangeSuccess={() => {
            setOpenUpdate(false);
          }}
          ref={userGroupPermissionsFormRef}
          userGroupId={userGroupId}
          onChangeLoading={setLoading}
        />
      </Modal>
    </div>
  );
};
export default UserGroupPermissionsTab;
