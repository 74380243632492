export const sidebarMenuMessages = {
  config: 'Cấu hình',
  usersAndPermissions: 'Người dùng & phân quyền',
  users: 'Người dùng',
  userGroups: 'Nhóm người dùng',
  permissions: 'Phân quyền',
  roles: 'Nhóm quyền',
  organizationUnit: 'Đơn vị/ phòng đội',
  customerSupportInformation: 'Thông tin hỗ trợ khách hàng',
  serviceTypes: 'Loại dịch vụ',
  frequentlyAskedQuestions: 'Hỏi đáp KB',
  serviceInstructions: 'Thông tin hướng dẫn',
  workingSchedules: 'Lịch nghỉ lễ, làm bù',
  shifts: 'Ca trực',
  reasons: 'Lý do bận của điện thoại viên',
  vipCustomers: 'Khách hàng vip'
};
