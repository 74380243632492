import { Checkbox, Col, Input, Row, Spin, Typography } from 'antd';
import { find } from 'lodash';
import { useState } from 'react';
import FilterOrigin, { FilterPopoverOptionItem } from './Filter';

type FilterCheckboxSearchProps = React.ComponentProps<typeof FilterOrigin> & {
  options: FilterPopoverOptionItem[];
  onSearch: (value: string) => void;
  loadMore?: () => void;
  loading?: boolean;
};
const FilterCheckboxSearch = ({
  value = [],
  options,
  children,
  icon,
  title,
  loadMore,
  onSearch,
  loading = false,
  ...props
}: FilterCheckboxSearchProps) => {
  const [valueTemp, setValueTemp] = useState(value);
  const content = (
    <Checkbox.Group
      onChange={(val) => {
        setValueTemp(val);
      }}
      value={valueTemp}
      style={{ maxWidth: 250 }}
    >
      {onSearch && <Input.Search className='mb-4' onChange={(val) => onSearch(val.target.value)} />}
      <Spin spinning={loading}>
        <div
          className='no-scrollbar -mx-6 max-h-[300px] w-[calc(100%+48px)] overflow-auto px-6'
          onScroll={async (e: any) => {
            const { target } = e;
            if ((target as any).scrollTop + (target as any).offsetHeight === (target as any).scrollHeight) {
              loadMore?.();
            }
          }}
        >
          <Row align='middle'>
            {options.map((o, idx) => (
              <Col span={24} key={o.value?.toString() || `key-${idx}`}>
                <Checkbox style={{ width: '100%' }} value={o.value}>
                  {o.label}
                </Checkbox>
              </Col>
            ))}
          </Row>
        </div>
      </Spin>
    </Checkbox.Group>
  );
  const childrenLabel = children ?? (
    <>
      {icon}
      {value && value?.length > 0 ? (
        <Typography.Paragraph
          className='mb-0 max-w-[175px] text-sm'
          ellipsis={{
            rows: 1,
            tooltip: true
          }}
        >
          {value.map((o: any) => find(options, { value: o })?.label).join(', ')}
        </Typography.Paragraph>
      ) : (
        <p className='mb-0 line-clamp-1'>{title}</p>
      )}
    </>
  );

  return (
    <FilterOrigin
      {...props}
      title={title}
      content={content}
      valueTemp={valueTemp}
      clearFilter={() => {
        setValueTemp([]);
      }}
      value={value}
      setValueTemp={setValueTemp}
    >
      {childrenLabel}
    </FilterOrigin>
  );
};

export default FilterCheckboxSearch;
