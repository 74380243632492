import { useState } from 'react';
import { useGetWorkingSchedulesQuery } from 'services';
import { FindAllWorkingScheduleDto } from 'types';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from 'utils';
const pageSize = DEFAULT_PAGE_SIZE;
export function useWorkingSchedulesPaging({ keyword }: Omit<FindAllWorkingScheduleDto, 'pageIndex' | 'pageSize'>) {
  const [pageIndex, setPageIndex] = useState(DEFAULT_PAGE_INDEX);
  const {
    data: workingSchedulesResponse,
    error,
    isLoading,
    isFetching
  } = useGetWorkingSchedulesQuery({
    pageIndex: pageIndex,
    pageSize,
    keyword
  });
  const handleChangePage = (newPageIndex: number) => {
    setPageIndex(newPageIndex);
  };

  return {
    workingSchedules: workingSchedulesResponse?.data.rows,
    count: workingSchedulesResponse ? workingSchedulesResponse.data.count : 0,
    pageIndex,
    isLoading: isFetching || isLoading,
    error,
    handleChangePage
  };
}
