import { Anchor, Divider, Layout, Menu, Typography } from 'antd';
import { DoubleArrowRightIcon } from 'assets';
import dayjs from 'dayjs';
import { useActiveMenu } from 'hooks';
import { find } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { SidebarMenuItems } from 'utils';

const { Sider } = Layout;

const SidebarSubmenu = () => {
  const { selectedMenu, selectedSubMenu } = useActiveMenu();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(true);
  const [time, setTime] = useState<Date>(new Date());
  let intervalId: NodeJS.Timeout;
  useEffect(() => {
    startClock();

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      stopClock();
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  const startClock = () => {
    updateClock();
    const now = new Date();
    /** Tính toán số milliseconds còn lại cho đến đầu phút tiếp theo */
    const delay = 60000 - (now.getSeconds() * 1000 + now.getMilliseconds());
    /** Đặt timeout để cập nhật lại đồng hồ chính xác vào đầu phút tiếp theo */
    setTimeout(function () {
      updateClock();
      intervalId = setInterval(updateClock, 1000 * 60);
    }, delay);
  };

  const updateClock = () => {
    setTime(new Date());
  };

  const stopClock = () => {
    clearInterval(intervalId);
  };

  const handleVisibilityChange = () => {
    if (document.hidden) {
      stopClock();
    } else {
      startClock();
    }
  };
  const menuItems = selectedMenu ? find(SidebarMenuItems, { key: selectedMenu })?.children || [] : [];
  const menuRef = useRef<HTMLDivElement>(null);
  return (
    <Sider
      width={288}
      ref={menuRef}
      className={twMerge('right-column no-scrollbar h-svh overflow-y-auto')}
      collapsedWidth={56}
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      <div className={twMerge('flex h-full flex-col', collapsed ? 'items-center' : '', collapsed ? 'pt-4' : 'pt-6')}>
        <Anchor offsetTop={collapsed ? 16 : 24} getContainer={() => menuRef.current!}>
          <div className={twMerge('bg-colorBgSpotlight', collapsed ? '' : 'flex flex-col px-4')}>
            <div
              onClick={() => {
                setCollapsed(!collapsed);
              }}
              className={twMerge(
                'flex h-12 w-12 cursor-pointer items-center justify-center rounded-[5px] hover:bg-spotlightHover',
                !collapsed && 'ml-auto'
              )}
            >
              <div
                className={twMerge(
                  'flex h-6 w-6 items-center justify-center rounded-full border border-white text-white transition',
                  !collapsed && 'rotate-180'
                )}
              >
                <DoubleArrowRightIcon width={12} height={12} />
              </div>
            </div>
            {collapsed && <Divider className='mb-3 mt-2 min-w-min bg-[#FFFFFF66]' />}
            <div className={twMerge('flex items-center', collapsed && 'justify-center')}>
              <Typography.Text
                className={twMerge(
                  'font-[Orbitron] font-extrabold text-white',
                  collapsed ? 'text-sm leading-[48px]' : 'text-2xl leading-[56px]'
                )}
              >
                {dayjs(time).format('HH:mm')}
              </Typography.Text>
            </div>
            {collapsed ? (
              <div className='flex h-12 flex-col items-center'>
                <Typography.Text className='text-0.5xl font-extrabold text-white'>
                  {dayjs(time).format('DD')}
                </Typography.Text>
                <Typography.Text className='text-[10px] font-extrabold text-white'>
                  Tháng {dayjs(time).format('M')}
                </Typography.Text>
              </div>
            ) : (
              <div>
                <Typography.Text className='font-semibold leading-[40px] text-white'>
                  {dayjs(time).format('dddd')}, {dayjs(time).format('DD/MM/YYYY')}
                </Typography.Text>
              </div>
            )}
            <Divider className={twMerge('mt-2 min-w-min bg-[#FFFFFF66]', collapsed ? 'mb-3' : 'mb-6')} />
          </div>
        </Anchor>
        <Menu
          className='bg-transparent pb-[72px]'
          theme='dark'
          mode='inline'
          selectedKeys={selectedSubMenu ? [selectedSubMenu] : []}
          items={menuItems.map((menu) => ({
            label: menu.name,
            icon: menu.icon,
            key: menu.key
          }))}
          onClick={({ key }) => {
            const featureSelect = find(menuItems, {
              key: key
            });
            if (featureSelect) navigate(featureSelect.href);
          }}
        />
      </div>
    </Sider>
  );
};

export default SidebarSubmenu;
