export const customerSupportInformationMessages = {
  title: 'Tiêu đề',
  icon: 'Biểu tượng',
  isNew: 'new',
  format: 'Định dạng',
  status: 'Trạng thái',
  create: 'Tạo mới thông tin hỗ trợ khách hàng',
  update: 'Cập nhật thông tin hỗ trợ khách hàng',
  content: 'Nội dung',
  uploadExcelTable: 'Tải file excel nội dung',
  statusEnum: {
    active: 'Hoạt động',
    inactive: 'Không hoạt động'
  }
};
